// eslint-disable-next-line no-restricted-imports -- lodash is used as a base for our own utils
import { maxBy, orderBy as lodashOrderBy } from 'lodash'

/**
 * @returns the most frequent occurring value in array as string
 */
export const findMostFrequentValue = <T>(array: T[]): T => {
  const groups = Array.from(groupBy(array, (el) => el).values())
  const largestGroup = maxBy(groups, (arr) => arr.length)
  if (!largestGroup) {
    throw new Error(`No values found`)
  }
  return largestGroup[0]
}

/**
 * @returns a map with array elements grouped by property returned by selector.
 * Keeps key type compared to lodash which converts keys to string.
 */
export function groupBy<T, K>(array: T[], selector: (el: T) => K) {
  return array.reduce((acc, value) => {
    const valueKey = selector(value)
    const bucket = acc.get(valueKey)
    if (bucket) {
      bucket.push(value)
    } else {
      acc.set(valueKey, [value])
    }
    return acc
  }, new Map<K, T[]>())
}

/**
 * @returns a function which takes two arguments, passes them through the given
 * function and alphabetically compares the results using {@link String.localeCompare},
 * while respecting numerical order (e.g. `'10'` comes after `'2'`)
 *
 * @example
 * ```ts
 * // Sort users by lastName
 * users.sort(composeCompareAlphanumeric((user) => user.lastName))
 * ```
 */
export const composeCompareAlphanumeric =
  <T>(getter: (item: T) => string) =>
  (a: T, b: T) =>
    compareAlphanumeric(getter(a), getter(b))

export const compareAlphanumeric = (a: string, b: string) =>
  a.localeCompare(b, undefined, { sensitivity: 'accent', numeric: true })

export const compareNullishLast = <T>(a: T, b: T): number =>
  Number(a == null) - Number(b == null)

type OrderByKey<T> = keyof T | ((item: T) => unknown)
type Order = 'asc' | 'desc'
export const orderBy = <T>(
  array: T[],
  key: OrderByKey<T> | OrderByKey<T>[],
  order: Order | Order[]
) => {
  return lodashOrderBy(array, key, order)
}
